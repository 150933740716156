import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

function Button({text}) {
    return (
        <button className="bg-yellow-300 hover:bg-yellow-400 text-slate-800 font-bold py-2 px-4 rounded">
            {text}
        </button>
    )
}

export default function Projects() {

    const loadCompleted = useRef(false);
    const [projectList, setProjectList] = useState([]);

    const fetchProjects = () => {

        axios.post("https://graphql.prepr.io/a56ffc145fa53d3168a9a3f26c604370e0555fe8ff9db4137e8b3a4c0fa04853", {
            query: `
            {
                Portofolios {
                    items {
                        _id,
                        title,
                        subtitle,
                        link,
                        cover {
                            url
                        },
                        description,
                        tags {
                            body
                        }
                    }
                }
            }
            `
        }).then(({data}) => {
            setProjectList(projectList => data.data.Portofolios.items);
        });

    }

    useEffect(() => {
        if(loadCompleted.current === false) {
            fetchProjects();
        }
        return () => { loadCompleted.current = true; }
    }, []);

    return (
        <div className="p-4">
            <div className="pt-4 pb-4">
                <h1 className="sm:text-4xl text-2xl font-bold text-center">
                    Projects
                </h1>
            </div>
            <div className="w-full">

                {projectList.map((item, index) => (
                    <div className="flex flex-wrap bg-white dark:bg-slate-700 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 border border-slate-100 dark:border-slate-700 shadow-sm mb-4"
                        key={index}   
                    >
                        <div className="sm:w-2/5">
                            <img alt="img" src={item.cover.url}/>
                        </div>
                        <div className="pt-4 pl-4 pr-4 sm:pt-0 sm:w-2/5">
                            <h2 className="text-lg font-bold">{item.title}</h2>
                            <h3 className="text-sm font-medium">{item.subtitle}</h3>
                            <div className="pl-4">
                                <ul className="text-slate-400 list-disc">
                                    {item.tags.map((tag, tagIndex) => (
                                        <li key={tagIndex}>{tag.body}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="pt-4 pl-4 pr-4 sm:pt-0 sm:w-1/5">
                            <a href={item.link} target="_blank"><Button text={'Preview'}/></a>
                        </div>
                    </div>
                ))}

                
            </div>
        </div>
    );
}