import React from 'react';

export default function Experience() {
    return (
        <div className="p-4">
            <div className="pt-4 pb-4">
                <h1 className="sm:text-4xl text-2xl font-bold text-center">
                    Experience
                </h1>
            </div>
        </div>
    );
}