import React from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Home from '../../pages/Home';
import Layout from '../../components/layout/Layout';
import Experience from '../../pages/Experience';
import Techstack from '../../pages/Techstack';
import Projects from '../../pages/Projects';

function AnimatedRoutes() {

  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Layout/>}>
          <Route path="/" element={<Home/>} />
          <Route path="/experience" element={<Experience/>} />
          <Route path="/projects" element={<Projects/>} />
          <Route path="/techstack" element={<Techstack/>} />
        </Route>
      </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes